<!-- Settings -->
<template>
  <div class="card card-custom" v-loading="loading">
      <div class="card-body p-0">
          <!--begin: Settings-->
          <div class="wizard wizard-1" id="kt_wizard_v1" data-wizard-state="step-first" data-wizard-clickable="true">
              <!--begin: Settings Body-->
              <div class="row justify-content-center my-10 px-8 px-lg-10">
                  <div class="col-xl-12  invoicepage">
                      <!--begin: Wizard Form-->
                      <el-form class="form" id="kt_form printSection">
                          <!--begin: Wizard Step 1-->
                          <div class="pb-5" data-wizard-type="step-content" data-wizard-state="current">                                
                               <h3 class="mb-10 font-weight-bold text-dark">
                                    <i class="flaticon2-user"></i> RETURN PRODUCT
                                </h3>                                                            
                                 <div class="row">
                                  <div class="col-xl-12">
                                    <el-tabs class="root-tab" v-model="activeTab" type="border-card">
                                      <el-tab-pane label="RETURN ORDER DETAILS" name="return_details">
                                        <el-row>
                                          <el-col :span="10"  >
                                            <el-form-item label="Customer Name" prop="customer_id">
                                                <el-select ref="customer_name" v-model="form.customer_id" filterable clearable remote  @change="getCustomerOrder($event)" placeholder="Type something to search customer"  v-loading="loading" style="width:100%" >                                     
                                                
                                                  <el-option
                                                    v-for="(customer, index) in customer_list"
                                                    :key="index"
                                                    :label="
                                                      customer.first_name +
                                                      ' ' +
                                                      (customer.last_name == null
                                                        ? ''
                                                        : customer.last_name)
                                                    "
                                                    :value="customer.id"
                                                    style="height: auto; padding: 10px 20px"
                                                  >
                                                </el-option>                                     
                                                </el-select>                                    
                                                <el-span class="form-err" v-if="form.errors.has('customer_id')" >{{form.errors.get('customer_id')}}</el-span>
                                            </el-form-item>
                                          </el-col> 
                             <el-col :span="6" :offset="1">
                                 <el-form-item label="Order Number" prop="order_number">
                                    <el-select clearable filterable remote v-model="form.order_id" 
                                     @change="getOrderDetails($event)" 
                                     placeholder="Select Order"  
                                     style="width:100%" :allow-create="true"                                      
                                     
                                     :disabled="order_list.length == 0 ? true : false">                            
                                       <el-option v-for="order in order_list" :key="order.id" :label="order.order_number" :value="order.id" ></el-option>
                                    </el-select>
                                    <span class="form-err" v-if="form.errors.has('order_id')" >{{form.errors.get('order_id')}}</span>
                                 </el-form-item>
                              </el-col>  
                               <el-col :span="6" :offset="1">
                                 <el-form-item label="Invoice Number" prop="invoice_number">
                                    <el-select clearable filterable v-model="form.order_id"  @change="getOrderDetails($event)" placeholder="Select Order"  style="width:100%" :allow-create="true">                            
                                       <el-option v-for="order in order_list" :key="order.id" :label="order.invoice_number" :value="order.id" ></el-option>
                                    </el-select>
                                    <span class="form-err" v-if="form.errors.has('order_id')" >{{form.errors.get('order_id')}}</span>
                                 </el-form-item>
                              </el-col>  
                            </el-row>
                            <el-row style="margin-top:10px;">
                                      <el-col :span="8"  v-if="order_details!=''">
                                          <div  class="billing-adr" style="padding-left:10px;">Return From :</div>
                                          <div  v-if="order_details!=''" style="padding: 8px;" >                                         
                                          <div style="padding: 3px;" v-if="customer">{{customer.first_name}}  {{customer.last_name ? customer.last_name : ''}}</div>
                                          <div style="padding: 3px;" v-if="order_details.billing_address_order.address_line_2">{{order_details.billing_address_order.address_line_2}}{{order_details.billing_address_order.address_line_1 ? ', Unit No : '+ order_details.billing_address_order.address_line_1: ''}}</div>
                                          <div style="padding: 3px;" v-if="order_details.billing_address_order.city_details">{{order_details.billing_address_order.city_details.city_name}}</div>
                                          <div style="padding: 3px;" v-if="order_details.billing_address_order.state_details">{{order_details.billing_address_order.state_details.state_name+' - '+order_details.billing_address_order.post_code}}</div>
                                          </div>
                                      </el-col>
                                      <el-col :span="8" align="left" :offset="8"  v-if="order_details!=''">
                                         <div class="billing-adr" style="padding-left:10px;">Return TO :</div>
                                         <div  v-if="order_details!=''" style="padding: 8px;">                                          
                                          <div style="padding: 3px;" v-if="raddress_to.website_name">{{raddress_to.website_name }}</div>
                                          <div style="padding: 3px;" v-if="raddress_to.address">{{raddress_to.address }}</div>
                                          <div style="padding: 3px;" v-if="raddress_to.city_details">{{raddress_to.city_details.city_name}}</div>
                                          <div style="padding: 3px;" v-if="raddress_to.state_details">{{raddress_to.state_details.state_name+' - '+raddress_to.post_code}}</div>
                                          </div>
                                      </el-col>
                            </el-row>
                                    <div class="row" >
                                    <div class="col-xl-12 ">  
                                        <el-form-item prop="products">                              
                                      <el-table :data="form.products" stripe border fit  style="width:100%" >
                                          <el-table-column
                                            type="index"
                                            width="50">
                                          </el-table-column>
                                          <el-table-column label="Product #" width="100">
                                              <template slot-scope="scope">
                                                <span>{{form.products[scope.$index].product.sku}}</span>                                         
                                              </template>
                                          </el-table-column>
                                          <el-table-column label="Product Name" :min-width="200">
                                              <template slot-scope="scope">
                                                <span>{{form.products[scope.$index].product.name}}</span>                                         
                                              </template>
                                          </el-table-column>
                                          <el-table-column label="Quantity" width="100" align="center">
                                              <template slot-scope="scope">
                                                <span>{{form.products[scope.$index].quantity}}</span>                                         
                                              </template>
                                          </el-table-column>
                                           <el-table-column label="Delivered Qty" width="100" align="center">
                                              <template slot-scope="scope">
                                                <span>{{form.products[scope.$index].delivery_quantity}}</span>                                         
                                              </template>
                                          </el-table-column>
                                          <el-table-column label="Return Quantity" width="150">
                                              <template slot-scope="scope">
                                                  <el-input-number v-model="form.products[scope.$index].return_quantity" :min="0" :max="form.products[scope.$index].delivery_quantity"  @change="getReturnPrice(scope.$index)"  :controls="false"></el-input-number>
                                              </template>
                                          </el-table-column>
                                          <el-table-column label="Unit Price" width="100" align="center">
                                              <template slot-scope="scope">
                                                <span>${{parseFloat(form.products[scope.$index].unit_price).toFixed(2)}}</span>                                          
                                              </template>
                                          </el-table-column>                                
                                                                        
                                          <el-table-column label="Total Price" width="100" align="center">
                                              <template slot-scope="scope">                                        
                                                <span>${{form.products[scope.$index].ret_total? parseFloat(form.products[scope.$index].ret_total).toFixed(2):'0.00'}}</span>                                         
                                              </template>
                                          </el-table-column>                                  
                                        </el-table>
                                           <span class="form-err" v-if="show_product_error">Please select a return product.</span> 
                                  </el-form-item> 
                                    <div class="row" style="margin-top:10px;">
                                      <div class="col-xl-12"><h4>Price Details</h4>                               
                                      </div>
                                  </div>
                                  <el-row class="pricing-section">
                                        <el-col :span="15">
                                        <el-form-item label="Return Reason :" prop="comments">
                                        <el-input type="textarea" :rows="4"  v-model="form.return_reason" @input.native="capitalize"></el-input>
                                        </el-form-item>
                                        <span class="form-err" v-if="form.errors.has('return_reason')" >{{form.errors.get('return_reason')}}</span>                                          
                                      </el-col> 
                                      <el-col :span="8" :offset="1">
                                        <el-form :label-position="labelPosition" label-width="150px" >
                                        <el-form-item label="Sub Total" prop="ret_sub_total" >
                                            <el-input v-model="form.ret_sub_total" auto-complete="nope" disabled :controls="false" :precision="2" class="return_product" ></el-input>
                                        </el-form-item>   
                                          <el-form-item label="Discount (-)" prop="discount">
                                            <el-input v-model="form.discount" auto-complete="nope" disabled class="return_product" :controls="false" :precision="2"></el-input>
                                        </el-form-item>
                                          <el-form-item label="Refund Shipping" prop="refund_shipping">
                                            <el-input v-model="form.refund_shipping" auto-complete="nope"  :controls="false" :precision="2" @change="calculateTotal" class="return_product"></el-input>
                                        </el-form-item>
                                        <el-form-item label="Adjustment Refund" prop="refund_adjustment">
                                          <el-input  v-model="form.refund_adjustment"  :controls="false" :precision="2" @change="calculateTotal" class="return_product"></el-input>
                                            
                                        </el-form-item>
                                        <el-form-item label="Restocking Fee" prop="adjustment_fee">
                                            <el-input v-model="form.adjustment_fee" auto-complete="nope" :controls="false" :precision="2" @change="calculateTotal" class="return_product"></el-input>
                                        </el-form-item> 
                                         <el-form-item label="Tax Total" prop="tax_total">
                                            <el-input v-model="form.ret_tax_total" auto-complete="nope" disabled class="return_product" :controls="false" :precision="2"></el-input>
                                        </el-form-item>                       
                                          <el-form-item label="Refund Total" prop="total">
                                            <el-input v-model="form.total" auto-complete="nope" disabled class="return_product" :controls="false" :precision="2"></el-input>
                                        </el-form-item>
                                        </el-form>
                                      </el-col>                           
                                      
                                    </el-row> 
                                    <div class="d-flex justify-content-center pt-5">
                                      <a @click="gotoTab('payment_process')" class="text-white">
                                        <div
                                          class="btn btn-md btn-primary font-weight-bold text-uppercase"                                                    >
                                          Next
                                        </div>
                                      </a>
                                    </div>
                                          <el-row style="margin-top:25px;">
                                              <el-form >
                                              <el-col :span="6" >                                             
                                                <!-- <el-form-item label="Return Order Taken By" prop="verified_by" >
                                                    <el-input v-model="form.order_taken_by" @input.native="capitalize" auto-complete="nope"  class="sub-total"
                                                    :class=" form.errors.has('order_taken_by') ? 'error required' : 'required' "></el-input>
                                                </el-form-item>  
                                                <span class="form-err" v-if="form.errors.has('order_taken_by')" >{{form.errors.get('order_taken_by')}}</span>  -->
                                              </el-col>                                     
                                            
                                              <el-col :span="5" :offset="2" style="margin-top:20px">
                                              <!-- <el-form-item >
                                                    <el-button type="primary" @click="createRefund">{{submit_btn_text}}</el-button>
                                              </el-form-item> -->
                                             
                                              </el-col>
                                              </el-form>
                                                </el-row>                               
                                              </div>
                                              </div>    
                                      </el-tab-pane>
                                      <el-tab-pane label="PROCESS PAYMENT" name="payment_process"  :disabled="isFormInValid">
                                        <el-card class="box-card">
                                            <div slot="header" class="clearfix">
                                              <span>Order Payment Method</span>
                                            </div>
                                            <div class="text item">
                                              <el-row>
                                              <el-col :span="10">Payment Status </el-col>
                                              <el-col :span="10" >
                                                <span class="badge mr-1 badge-success" v-if="view.payment_status == 'paid'">Paid</span>
                                                <span class="badge mr-1 badge-primary" v-if="view.payment_status == 'partially_paid'">Partially Paid</span>
                                               
                                              </el-col>
                                              </el-row>
                                            </div>                                              
                                            <div v-for="(pay,index) in payments" :key="index" class="text item mt-7">
                                               <el-row>
                                                <el-col :span="10">{{pay.payment_method}} ({{pay.payment_date}}) </el-col>
                                                <el-col :span="10" >${{pay.due_amount.toLocaleString("en-US")}}</el-col>
                                              </el-row>
                                            </div>
                                          </el-card>
                                          <el-card class="box-card">
                                            <div slot="header" class="clearfix">
                                             <el-row>
                                              <el-col :span="10"> Order Balance</el-col>
                                              <el-col :span="10" v-if="view.invoice">${{view.invoice.balance_amount.toLocaleString("en-US")}}</el-col>
                                              </el-row>
                                            </div>                                          

                                            <div class="text item mt-9">
                                              <el-row>
                                                <el-col :span="10"><i class="el-icon-money"></i> {{refund_method}}</el-col>
                                                <el-col :span="10" >{{this.form.total}}</el-col>
                                              </el-row>                                                
                                            </div>
                                            <div class="text item mt-9">
                                              <el-row>
                                                <el-col :span="10"><label>Return Taken By</label></el-col>
                                                <el-col :span="10" >
                                                  <el-input v-model="form.return_taken_by"
                                                   :class=" form.errors.has('return_taken_by') ? 'error required' : 'required' "></el-input>
                                                   <span class="form-err" v-if="form.errors.has('return_taken_by')">{{ form.errors.get("return_taken_by") }}</span>
                                                </el-col>
                                                    
                                              </el-row>                                                
                                            </div>
                                            <!-- <div v-for="o in 4" :key="o" class="text item">
                                              {{'List item ' + o }}
                                            </div> -->
                                          </el-card>
                                         <div class="d-flex justify-content-center pt-5">
                                           <a @click="gotoTab('return_details')" class="text-white mr-5">
                                            <div
                                              class="btn btn-md btn-warning font-weight-bold text-uppercase"                                                    >
                                              Back
                                            </div>
                                          </a>
                                          <a class="text-white" @click="createRefund">
                                            <div
                                              class="btn btn-md btn-primary font-weight-bold text-uppercase"                                                    >
                                              Return Order
                                            </div>
                                          </a>
                                        </div>
                                      </el-tab-pane>
                                    </el-tabs>
                                  </div>
                                 </div>
                               
                                                         
                                     <!-- <el-row>                                      
                                    <el-col :span="24" align="left">
                                    <div style="text-align: center;"><b>If you have any questions about this order, please contact</b></div>
                                    <div style="text-align: center; font-size:15px;"><b>EMAIL: INFO@CTSBUILDINGSUPPLIES.COM</b></div>
                                    </el-col>
                                     </el-row>                                      -->
                            
                             </div>
                          
                      </el-form>
                      <!--end: Settings Form-->
                  </div>
              </div>
              <!--end: Settings Body-->
          </div>
      </div>
      <!--end: Settings-->
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import moment from 'moment';
import {downloadPdf} from "@/api/order";
//import {getCorder} from "@/api/orderStatus"
import SendEmail from '@/components/SendEmail';
import {getOrderList,getOrderDetails} from "@/api/returnproduct";
import {getAllCustomers} from "@/api/customer";

import Form from '@/core/services/api.form.services'
import {getPayments,getPaymentDetails} from "@/api/invoice";
export default {
name: 'view-order',
components: {},
 data() {
  return {
    loading: false,
    view:[],
    viewitem:[],
    view_type: 'invoice',
    labelPosition:"right",
    activeTab: "return_details",
    isFormInValid :true,
     api_url:'return/order/create',
     form : new Form({     
      customer_id:null,
      invoice_id:null,
      order_number : null, 
      order_id:null,
      return_from_id:null,
      return_to_id:null,
      credit_amount: null,       
      ret_sub_total:'$ 0.00',
      ret_inside_total:'$ 0.00',
      discount:'$ 0.00',    
      website_id:null,   
      return_reason:null,
      ret_tax_total:'$ 0.00',
      refund_shipping:'$ 0.00',
      refund_adjustment:'$ 0.00',
      adjustment_fee:'$ 0.00',  
      return_amount_type:null,   
      return_taken_by:null,   
      total:'$ 0.00',                
        products:[
        {
          product_id:null,
          quantity:null,
          delivery_quantity:null,
          return_quantity:null,
          unit_price:'0.00',            
          tax_id:null,
          tax_price:'0.00',           
          ret_total:'0.00',
          ret_inside:'0.00',
          extra_discount:'0.00',
          ret_taxAmount :'0.00',
          discount : '0.00',            
          tax_list:[],
          tax:'0.00',
          tax_amount:null,
          inside_per:'0.00',
          product: [{
             product_id:null,
             sku: null,
             name:null,
             tax_amount:'0.00',             
           }] ,
           
        }
      ]

     }),
      refund_method:null,
      show_product_error: false,
      submit_btn_text:'Return Offline',
      product_list: [],      
      order_list:[],     
      order_details: [],
      raddress_from:[],
      raddress_to: [],
      customer_list:[],
      customer:[],
      payments:[],
      orderStatus: [
          { id: "pickup", text: "Ready to Pick Up" },
          { id: "processing", text: "Processing" },
          { id: "on-hold", text: "On Hold" },
          { id: "completed", text: "Completed" },
          { id: "cancelled", text: "Cancelled" },
          { id: "refunded", text: "Refunded" },
          { id: "failed", text: "Failed" },
    ],
  }
},
mounted() {
  this.$store.dispatch(SET_BREADCRUMB, [
     { title: "Dashboard", route: "/dashboard" },
    { title: "Return Product" }
  ]);
},
created() {
  this.getAllCustomers();
  this.fetchData(1);  
  
},
methods: {
  fetchData() {
    this.loading = true
    
    
  },
  getAllCustomers(){
       this.customer_list = [];
      this.loading = true
      getAllCustomers().then(response => {
        //console.log(response);        
        this.customer_list = response.data.data.data
        this.form.products = [];
        this.loading = false
      })  
    },
    getCustomerOrder(customer_id){
    
        this.order_list = [];
        this.form.products = [];
        this.raddress_to = [];
        this.order_details =[];       
        this.form.order_id = '';
        this.form.invoice_number = '';
        // this.form.ret_sub_total = '0.00';
        // this.form.ret_tax_total = '0.00';
        // this.form.refund_shipping = '';
        // this.form.refund_adjustment = '';
        // this.form.adjustment_fee = '';
        // this.form.total= '0.00';
             
      //this.loading = true
          getOrderList(customer_id).then(response => {              
            this.order_list = response.data.data
            if (this.order_list.length == 0) {
            const h = this.$createElement;
            this.$notify({
              message: h(
                "i",
                {
                  style: "color: red",
                },
                "This Customer No Return Product is Available"
              ),
              offset: 200,
              type: "warning",
            });
          }
           // this.loading = false       
          })   
      },
   gotoTab(tabname) {
    this.loading = true;
    let errMsg = false;
    this.show_product_error = false;
    this.isFormInValid = true;
     this.form.errors.add("return_reason", "");     

    if(this.form.total == '$ 0.00'){
      errMsg = true;
    }

     if (errMsg) {
      this.show_product_error = true;
      this.loading = false;    
      return false;
    } 
     
    if(!this.form.return_reason){     
       this.form.errors.clear();
       this.form.errors.add("return_reason", "* Return Reason is required.");
    }else {
        this.activeTab = tabname;
        this.isFormInValid = false;
      }
   
    this.loading = false;
   },

   getOrderDetails(order_id){
    this.loading = true

    getOrderDetails(order_id).then(response => {
      this.view = response.data.data 
      this.form.customer_id = response.data.data.customer_id;        
      this.customer = response.data.data.customer;
      this.order_details = response.data.data;
      this.form.return_from_id = response.data.data.billing_address_id;
      this.form.return_to_id = response.data.data.website_id; 
      this.raddress_to = response.data.data.website_details
      this.form.products = response.data.data.order_items; 
      this.form.invoice_id = response.data.data.invoice_id ; 
      this.form.products.map((product)=>{
            product.return_quantity = 0;
        })
        
      getPayments( this.form.invoice_id).then(response => {         
        this.payments = response.data.data         
      }); 
      this.getReturnmethod(this.form.invoice_id);
      this.loading = false 
    })   
  },
  printWindow: function () {		
     window.print();
  },
  format_date(value){
      if (value) {
      return moment(String(value)).format('DD-MM-YYYY')
      }
  },
  getReturnmethod(invoice_id){
     getPaymentDetails(invoice_id).then(response => {
       let payment = response.data.data;    
       let cardpayment = this.payments[0];          
       let paymentCount = Object.keys(payment).length;
        if(paymentCount == 1){
          if(Object.keys(payment) == 'Term'){ 
            this.refund_method = 'Refund in Credit Memo'
            this.form.return_amount_type = 'credit_memo'
          } else if(Object.keys(payment) == 'Credit Card'){ 
               this.refund_method = 'Refund is '+ cardpayment.brand + '**** ' +cardpayment.last_4;
               this.form.return_amount_type = 'refund_online'
          }else{
            this.refund_method = 'Refund in Credit Memo'
            this.form.return_amount_type = 'credit_memo'
          }
        }else{
          this.refund_method = 'Refund in Credit Memo'
          this.form.return_amount_type = 'credit_memo'
        }
     });
  },
   getReturnPrice(index){
    let pid = this.form.products[index].product_id;       
    let unit_price = this.form.products[index].unit_price;
    //let inside_price = this.form.products[index].inside_per; 
    //console.log(this.form.products[index].ret_quantity);
    let r_quantity = this.form.products[index].return_quantity ? this.form.products[index].return_quantity : 0;
    let tax_percentage = 0;
    let unit_tax_amount = 0;
    let tax_id = parseInt(this.form.products[index].tax_id);
    let discount = (this.form.products[index].unit_price*this.form.products[index].extra_discount)/100;
 
    if(tax_id){
      tax_percentage = this.getTaxAmountByTaxId(tax_id, index);
    }
     if(tax_percentage > 0){
      unit_tax_amount = this.calculateTaxAmount(unit_price,tax_percentage);
    }
    // let inside = 0;
    // if(inside_price>0){
    //   inside = (unit_price * inside_price) / 100;
    // }      
    this.form.products[index].tax_list = this.getProductTaxListById(pid);     
    this.form.products[index].ret_taxAmount = r_quantity*unit_tax_amount;
    this.form.products[index].ret_total = (r_quantity*unit_price + r_quantity*unit_tax_amount - r_quantity*discount);     
    this.form.products[index].discount = r_quantity*discount;
    this.form.products[index].tax = unit_tax_amount;
  
    this.calculateTotal();
  },
  getProductPriceById(product_id){
    let productPrice = 0;
    if(product_id){
      this.product_list.map((product) =>{
        
        //console.log(index+"--"+product_id+"---"+product.id);
        if(product_id == product.id){
          productPrice = parseFloat(product.p_sale_price);
        }
      })
    }
    return productPrice;      
  },
  getTaxAmountByTaxId(tax_id,pindex){
      let tax_amount = 0;
      if(tax_id){
        this.form.products[pindex].tax_list.map((tax) =>{
          
          if(tax_id == tax.tax_id){
            tax_amount = parseFloat(tax.tax_amount);
          }
        })
      }
      return tax_amount;      
    },
    calculateTaxAmount(unit_product_price, tax_percentage){
      let tax_amount = 0;
      if(tax_percentage > 0){
        tax_amount = (unit_product_price*tax_percentage)/100;
      }
      return tax_amount;
    },
   getProductTaxListById(product_id){
    let tax_list = 0;
    if(product_id){
      this.product_list.map((product) =>{         
        if(product_id == product.id){
          tax_list = product.tax_list;
        }
      })
    }
    return tax_list;      
  },

  getProductTaxById(product_id){
    let productTax = 0;
    if(product_id){
      this.product_list.map((product,index) =>{
        console.log(index+"--"+product_id+"---"+product.id);
        if(product_id == product.id){
          productTax = parseFloat(product.app_tax);
        }
      })
    }
    return productTax;      
  },   
 


    calculateTotal() {
      let sub_total = 0;
      let tax_total = 0;
      let refund_shipping = 0;
      let refund_adjustment = 0;
      let adjustment_fee = 0;
      let discount = 0;
      if(this.form.refund_shipping){
          var RefundShipping =  this.form.refund_shipping.replace(/\$|,/g, '')?this.form.refund_shipping.replace(/\$|,/g, ''):0.00;
   
        refund_shipping = RefundShipping;
        this.form.refund_shipping = "$ " + parseFloat(RefundShipping).toFixed(2);
      }
     if(this.form.refund_adjustment){
      var RefundAdjustment =  this.form.refund_adjustment.replace(/\$|,/g, '')?this.form.refund_adjustment.replace(/\$|,/g, ''):0.00;
   
       refund_adjustment = RefundAdjustment;
       this.form.refund_adjustment = "$ " + parseFloat(RefundAdjustment).toFixed(2);
     }
      if(this.form.adjustment_fee){
        var Adjustment =  this.form.adjustment_fee.replace(/\$|,/g, '')?this.form.adjustment_fee.replace(/\$|,/g, ''):0.00; 
   
        adjustment_fee = Adjustment;
        this.form.adjustment_fee = "$ " + parseFloat(Adjustment).toFixed(2);
      }
     
      this.form.products.map((product) =>{ 
        if(product.ret_total != undefined){
           sub_total = sub_total + product.ret_total;
           tax_total = tax_total + product.ret_taxAmount;
           discount = discount + product.discount;            
        }   
      }) 
       const tax = (sub_total*13/100);
      this.form.ret_sub_total = '$ '+parseFloat(sub_total).toFixed(2);
      this.form.discount = '$ '+parseFloat(discount).toFixed(2);
      this.form.ret_tax_total = '$ '+parseFloat(tax).toFixed(2);
      this.form.total = '$ '+(sub_total + tax - refund_shipping - refund_adjustment - adjustment_fee - discount).toFixed(2);
                    
    },
  
    
    addDiscount(){  
    let discountTotal; 
      discountTotal = this.form.sub_total - this.form.discount - this.form.credit_amount + this.form.tax_total;
      this.form.total = discountTotal.toFixed(2)
    },     
   
    addUsedCredit(){        
      if(this.form.is_credit_used == 1){             
           this.form.total = this.form.sub_total + this.form.tax_total - this.form.discount - this.form.credit_amount ;            
      }else{
           this.form.total = this.form.sub_total - this.form.discount + this.form.tax_total ;             
      }        
    },
  
  handleActions(evt){
    if (evt === 1){
      this.printWindow();
    }else if (evt === 2){
      this.loading=true;
      downloadPdf(this.$route.params.order_id, this.view_type).then(response => {
        //console.log(response.data);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'invoice.pdf');
        document.body.appendChild(link);
        link.click();
        this.loading = false;
      }).catch(err => {
        console.log(err);
        this.loading = false;
      });
    }else if (evt === 3){
      let customer = this.view.customer;
      let order_id = this.view.id;
      let type = this.view_type;

      this.$modal.show(SendEmail, {customer, type, order_id}, 
        { draggable: true, transition: 'pop-out',height: 'auto', clickToClose: false, adaptive: true, width: '500px' })
    }
  } ,
   createRefund(){
     this.loading = true;
       this.$bvModal.msgBoxConfirm('Are you sure you want to process this refund ?', {
            title: 'Please Confirm',
            size: 'md',
            buttonSize: 'sm',
            okVariant: 'danger',
            okTitle: 'YES',
            cancelTitle: 'NO',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true
          }).then(value => {
            if(value){ 
            this.form.post(this.api_url).then((response) => {
            if(response.status){
                this.$router.push({ path: '/all/order/list' });
                this.loading=false;
                this.$showResponse('success', response.message);
            }
          }).catch((error) => {
              this.loading = false;
                console.log(error)
              })
            }else{
              return false;
            }
          });
     this.loading = false;
   },
}
};
</script>

<style >
  hr.invoice-line{
      border-top: 1px solid black;
      width: 100%;
  }
  .billing-adr, .shipping-adr{
       font-size: 15px; 
       background-color:#366092; 
       color: #ffffff;
       padding:3px;
      border:1px solid #366092;
  }
  .invoice-header{
      background-color:#366092; 
      color: #ffffff;
  }
  #website-table th, #website-table td {
    border: 1px solid #366092; padding: 5px;
  }
  .tab-row-hover {
      border: 1px solid #366092;
  }
  .add-info{
    margin-bottom: 20px;
  }
  .default-logo img{
    width: 125px;margin-top:30px;margin-left: 10px;
  }
   .return_product .el-input__inner{
    /* padding-left: 0px;
    padding-right: 8px; */
     border: 2px solid #3699ff;
     text-align: end !important;
    
  }
  @media print {
     
      .print-section, .download-btn, .subheader, .action_btn, .action_dropdown {
          visibility: hidden;
          -webkit-print-color-adjust: exact;
      }
       .billing-adr, .shipping-adr{
       font-size: 15px; 
       background-color:#366092; 
       color: #ffffff;
       -webkit-print-color-adjust: exact;
      
  }

  .table th { 
      background-color: #366092 !important; 
       color: #ffffff !important;
       -webkit-print-color-adjust: exact;
  } 

  
  

}

</style>